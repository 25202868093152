/*
    <!-- ======== GLOBAL SCRIPT SETTING ======== -->
    1. Handle YouTube Search


    <!-- ======== APPLICATION SETTING ======== -->
    Main Controller
 */


/* 1. Handle YouTube Search
 ------------------------------------------------ */
var handleSearch = function() {
    "use strict";

    function checkQueryMinLength(query) {
        return query.length >= appConfig.minQueryLength;
    }

    $('#search-form').on('submit', function() {
        if (!checkQueryMinLength($('#global-search-text').val())) {
            toastr.error(trans.searchLengthError);
            return false;
        }
    });

    $('body').on('click', '#next-results', function(e) {
        e.preventDefault();
        var btn = $(this);
        $.ajax({
            type: 'post',
            url: '/next-results',
            data: {
                id: btn.data('id'),
                page: btn.data('page')
            },
            dataType: 'json'
        }).done(function(res) {
            var grid = document.getElementById('posts');
            var nextPosts = $('<div/>', {id: 'next-posts'});
            nextPosts.html(res.view);

            $(nextPosts.children('.well')).each(function(key, val) {
                salvattore.appendElements(grid, [val]);
            });

            if (res.page != undefined) {
                btn.data('page', res.page);
            } else {
                btn.remove();
            }
        });
    });
};

var handlePostDelete = function() {
    "use strict";
    $('body').on('click', '.del-post-btn', function(e) {
        e.preventDefault();

        var btn = $(this);
        if (confirm(trans.sureToDelete)) {
            $.ajax({
                type: 'post',
                url: '/administrator/delete-post',
                data: {
                    id: btn.data('id')
                },
                dataType: 'json'
            }).done(function(res) {
                if (res.err) {
                    toastr.error(res.msg);
                } else {
                    toastr.success(res.msg);
                }
            });
        }
    });

};

var handleBasicSettings = function() {
    "use strict";

    /* Setup x-csrf-token for ajax requests */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    // Toastr basic options
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "10000",
        "extendedTimeOut": "3000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "escapeHtml": false
    };

    /* Textarea auto-expand */
    $(document)
        .one('focus.textarea', '.autoExpand', function () {
            var savedValue = this.value;
            this.value = '';
            this.baseScrollHeight = this.scrollHeight;
            this.value = savedValue;
        })
        .on('input.textarea', '.autoExpand', function () {
            var minRows = this.getAttribute('data-min-rows') | 0,
                rows;
            this.rows = minRows;
            rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 19);
            this.rows = minRows + rows;
        });

    /* Contact form submit */
    $('#conact-us-form').submit(function(e) {
        e.preventDefault();
        var form = $(this);

        $.ajax({
            type: 'post',
            url: '/contact-us',
            data: {
                text: $('textarea[name="contact-us-text"]').val()
            },
            dataType: 'json'
        }).done(function(res) {
            if (res.err) {
                toastr.error(res.msg);
            } else {
                toastr.success(res.msg);
                form[0].reset();
                form.find('textarea').attr('rows', 1);
            }
        });
    });

    $('body').on('click', '.cell-content input', function(e) {
        e.preventDefault();
    });

    $('body').on('click', '.edit-post-title', function(e) {
        e.preventDefault();
        var parent = $(this).parent();
        var container = $('<div>' , {class: 'input-group input-group-sm'});
        var editor = $('<input>', {type: 'text', class: 'form-control', value: parent.find('span').text().trim()});
        var saveBtn = $('<span>', {class: 'input-group-btn btn-success'});
        saveBtn.html('<button class="btn btn-success save-post-title" type="button" data-id="'+$(this).data('id')+'" data-slug="'+$(this).data('slug')+'"><i class="fa fa-save"></i></button>');
        parent.html(container.append(editor).append(saveBtn));
    });

    $('body').on('click', '.save-post-title', function(e) {
        e.preventDefault();
        var btn = $(this);
        var input = btn.parent('span').siblings('input');

        $.ajax({
            type: 'post',
            url: '/administrator/update-post-title',
            data: {
                id: btn.data('id'),
                title: input.val(),
                slug: btn.data('slug')
            },
            dataType: 'json'
        }).done(function(res) {
            if (!res.err) {
                btn.closest('.pt').html(res.html);
            } else {
                toastr.error(res.msg);
            }
        });
    });

    if($.cookie('fb_popup') == undefined) {
        $.cookie('fb_popup', 0);
    } else {
        if ($.cookie('fb_popup') == 0) {
            window.setTimeout(function() {
                $('#facebook-modal').modal('show');
            }, 10000);
            $.cookie('fb_popup', 1, { expires: 7, path: '/' });
        }
    }
};




/* Main Controller
 ------------------------------------------------ */
var Main = function () {
    "use strict";

    return {
        //main function
        init: function () {
            this.initBasicSettings();
            this.initSearch();
            this.initPost();
        },
        initBasicSettings:function() {
            handleBasicSettings();
        },
        initSearch: function() {
            handleSearch();
        },
        initPost: function() {
            handlePostDelete();
        }
    };
}();